const urls = {
  baseURL: process.env.REACT_APP_BASE_URL,
  login: "auth/login/",
  resendOtp: "auth/resend-otp/",

  logout: "auth/logout/",
  crmAssign: "auth/crmAssign/",
  SendDailyCollection: "reports/send-daily-collection/",
  updateUpr: "auth/updateUpr/",
  // updateInt: "auth/updateInt/",
  updateCancelledStatus: "auth/update_cancelled_status/",
  crmremark:"auth/crmremark/",
  delete_document:"auth/delete_document/",
  changePwd: "auth/change-password/",
  search: "leads/search/overall/",

  // tele caller
  leads: "leads/",
  leadDetail: (id) => `leads/${id}/`,
  addFollowUp: (id) => `leads/${id}/add_followup/`,
  resendEnquiry: (id) => `leads/${id}/resend_enquiry/`,
  leadHistory: (id) => `leads/${id}/view_history/`,
  getSalesTeam: (id) => `leads/${id}/get_sales_team/`,
  checkActiveSM: (id) => `leads/${id}/check_active_sm/`,
  checkActiveLeadSM: (id, lead) => `leads/${lead}/lead_active_sm/`,
  salesTeamReport: "leads/sales_team_report/",
  checkEnquiry: "leads/check_enquiry/",
  review_assign_sm: "leads/review_assign_sm/",
  update_count: "leads/update_count/",

  // portal leads
  portalLeads: "leads/portals/",
  portalLeadsDetail: (id) => `leads/portals/${id}/`,
  assignPortalLeads: "leads/portals/assign_leads/",

  // Super Receptionist
  superReceptionist: "leads/super-receptionist/",
  portalReceptionist: (id) => `leads/super-receptionist/${id}/`,
  assignReceptionistLeads: "leads/super-receptionist/assign_leads/",

  // Super Receptionist
  review: "leads/review/",
  portalReviewassign: (id) => `leads/review/${id}/`,
  
  // marketing manager
  addTarget: "teams/target/",
  emplList: "teams/target/list_employee/",
  targetList: "teams/target/",
  campaignBudget: "master-values/campaign-budget/",
  customerFeedback: "leads/tele-caller/feedback/",
  //add master

  //sales
  crmSales: "crms/",
  approveDocuments: "crms/approve-documents",
  updateInt: "crms/update_customer_interest/",
  sendBulkSms:"auth/send-bulk-sms",

};

const teleLeads = "leads/tele-caller/";

export const teleCallerUrls = {
  teamList: teleLeads + "my_teams_list/",
  enquiryTabs: teleLeads + "enquiry_tab/",
  reportTabs: teleLeads + "report_tab/",
  report: teleLeads + "report/",
  targets: teleLeads + "my_target/",
  addSalesManager: (id) => `${teleLeads}${id}/add_sales_manager/`,
  editSalesManager: (id) => `${teleLeads}${id}/edit_sales_manager/`,
  removeSalesManager: (id) => `${teleLeads}${id}/remove_sales_manager/`,
  tabs: "users/tele-caller/lead_tab/",
  lead_list: "users/tele-caller/lead_list/",
  approveMul: "users/tele-caller/all_approved_deny/",
  salesSVDReport: "users/tele-caller/sales_svd_report/",
  teamTargetReport: "users/tele-caller/team_target_report/",
  feedback: teleLeads + "feedback/"
};

const masterValues = "master-values/";

export const masterUrls = {
  master: masterValues,
  activeMaster: masterValues + "is_active/",
  addProject: "projects/project/",
  addLocation: masterValues + "location/",
  addSource: masterValues + "sources/",
  addCampaign: masterValues + "campaign/",
  addPrimarySource: masterValues + "primary-source/",
  campaignBudget: masterValues + "campaign-budget/",
  request: masterValues + "requests/",
  requestType: masterValues + "request-type/",
};

export const marketingManagerUrls = {
  dashboard: "users/marketing-manager/dashboard/",
  reports: "users/marketing-manager/",
  reportTabs: "users/marketing-manager/report_tab/",
  sales: "users/marketing-manager/get_sales/",
  site: "users/marketing-manager/salesmanager_site/"
};

const salesUrl = "leads/sales-manager/";
const projects = "projects/";

export const salesManagerUrls = {
  leads: salesUrl,
  tabs: salesUrl + "lead_tab/",
  towers: projects,
  projects: `${projects}project/layout_apartment/`,
  blockApartment: `${projects}project/blocking_apartment/`,
  unBlockApartment: (id) => `${projects}project/${id}/unblocking_apartment/`,
  aprtDetail: (id) => `${projects}cost-sheet/apartment/${id}/`,
  towerReport: `${projects}project/tower_report/`,
  masterReport: (id) =>
    `${projects}project/project_master_availability/?project=${id}`,
  booking: "bookings/",
  // bookingApproval:"bookings/approverejectbookings/",
  bookingTabs: "bookings/booking_count/",
  costSheet: `projects/cost-sheet/apartment/pdf/`,
  reports: `${salesUrl}report/`,
  attendance: `${salesUrl}attendance/`,
  actactive: `${salesUrl}actactive/`,
  unfollowupleads: `${salesUrl}unfollowedlead/`,
  overallmissedfollowup: `${salesUrl}overallmissedfollowup/`,
  updatestatus :salesUrl+"update_status/"
};

const gmUrl = "users/sales-general-manager/";

export const salesGmUrls = {
  dashboard: gmUrl + "dashboard/",
  salesTeamLead: gmUrl + "sales_team_leads/",
  lead: gmUrl + "lead/",
  tabs: gmUrl + "lead_tab/",
  approveLead: (id) => `${gmUrl}${id}/approved_deny/`,
  approveBooking: `bookings/all_approved_deny/`,
  approveMul: "users/sales-general-manager/all_approved_deny/",
  reports: "users/sales-general-manager/reports/",
  reportsPagination: "users/sales-general-manager/reports_pagination/",
  lead_reports: "users/sales-general-manager/lead_reports/",
  sendMail: "auth/send-report-email/",
};

export const salesCOUrls = {
  leads: "users/sales-coordinator/list_leads/",
  leadTab: "users/sales-coordinator/list_lead_count/",
  conversion: "users/sales-coordinator/booking_leads/",
  conversionTab: "users/sales-coordinator/booking_lead_count/",
  allocateCab: (id) => `users/sales-coordinator/${id}/allocate_cab/`,
  reports: "users/sales-coordinator/reports/",
};

export const frontOfficeExecutiveUrls = {
  report_tab: "users/front-office/report_tab/",
  report: "users/front-office/",
};

export const crmExecutiveUrls = {

  approveLead: (id) => `${crmExecutiveUrls}${id}/approved_deny/`,
  approveBooking: `bookings/all_approved_deny/`,
  approveMul: "users/sales-general-manager/all_approved_deny/",
  reports: "users/sales-general-manager/reports/",
  reportsPagination: "users/sales-general-manager/reports_pagination/",
  lead_reports: "users/sales-general-manager/lead_reports/",
  sendMail: "auth/send-report-email/",
  monthlyProjectionReport: "crms/monthly_projection_report/",
  chequeStatusReport: "crms/cheque_status_report/",
  customerDueReport: "crms/customer_due_report/",
  InterestReport: "receipts/interest_report/",
  actualSalesReport: "crms/actual_sales_report/",
  cusomerDatabaseReport: "crms/customer_database_report/",
  totalno:"reports/get_total_no/",
  agreementStatusProjectwiseReport: "crms/agreement_status_projectwise_report/",
  dailyCollectionReport: "crms/daily_collection_report/",
  daily_collection_report_projectwise:"crms/daily_collection_report_projectwise/",
  MonthCollectionReportProjectwise:"crms/monthly_collection_report_projectwise/",
  dailyCollectionCrmwiseReport:"crms/daily_collection_report_crmwise/",
  MonthlyCollectionCrmwiseReport: "crms/monthly_collection_report_crmwise/",
  MonthlyCollectionReport: "/crms",
  leads: "/crms",
  handoverApproval:"crms/handoverbookings",
  customerBirthday:"crms/customer_birthday",
  customerAnniversary:"crms/customer_anniversary",
  registrationReminder:"crms/registration-reminder",

  leads_id: "/crms/crmIds",
  crmAssignee: "crms/crm_assignee/",
  cancelled: "/crms/?is_canceled=1",
  crmCustomers: "crms/customers/",
  activityStatus: "crms/booking-call-activities/",
  digitalDocument: "crms/booking-digital-document/",
  email: "emails/",
  sendReportEmail: "emails/send_report_pdf_email/",
  projects: "crms/projects/",
  paymentNotes: "payment-notes/",
  creditNotes: "credit-notes/",
  debitNotes: "payment-notes/debit-notes/",
  advanceCreditNotes: (booking_id) =>
    `payment-notes/credit-notes/advance_notes/?booking=${booking_id}`,
  receipts: "receipts/",
  advanceReceipts: "receipts/advance_receipts/",
  getRequestValue:
     "receipts/get_request_value/",
  getUprReport:
     "receipts/get_upr_report/",
  getInterstReport:
     "receipts/get_interst_report/",       

  advanceAmountAdjustment: (receipt_id, booking_id) =>
    `${crmExecutiveUrls.receipts}${receipt_id}/advance_amount_adjustment/?booking=${booking_id}`,
  getCreditNote: (notes_id, booking_id) =>
    `${crmExecutiveUrls.paymentNotes}credit-notes/${notes_id}/?booking=${booking_id}`,
  advanceCreditNoteAdjustment: (notes_id, booking_id) =>
    `${crmExecutiveUrls.paymentNotes}credit-notes/${notes_id}/advance_amount_adjustment/?booking=${booking_id}`,
  bankReconciliation: "receipts/bank-reconciliation",
  entryForBankReconciliation: (receipt_id) =>
    `receipts/bank-reconciliation/${receipt_id}/update_reconciliation/`,
  getBookingAmountAdjustment: (bookingId) =>
    `receipts/${bookingId}/get_booking_amount_adjustment/`,
  // getRequestType: (requestId) =>
  //   `receipts/${requestId}/get_request_type/`,
  
};

const documentsUrl = "documents/";
export const documentsUrls = {
  tower_availability: documentsUrl + "tower/availability/",
  statement_of_customer: (id) => documentsUrl + `statement-of-customer/${id}/`,
  downloadnocexcel:"documents/noc-excel-download/",
};

export const ReportsUrls = {
  reports: "reports/",
  agreementDoneMonthwise: "reports/get_agreement_done_monthwise",
  registrationDoneMonthwise: "reports/get_registration_done_monthwise",
  totalno:"reports/get_total_no",
  agreementStatusProjectwiseReport: "reports/get_agreement_status_projectwise",
  agreementStatusCrmwiseReport: "reports/get_agreement_status_crmwise",
  agreementdetails: "reports/get_agreement_details",
  dailyCollectionProjectwiseReport: "reports/get_daily_collection_projectwise",
  dailyCollectionCrmwiseReport: "reports/get_daily_collection_crmwise",
  dailyCollectionReport: "reports/get_daily_collection",
  payoutProjectwise: "reports/get_payout_projectwise",
  payoutBankwise: "reports/get_payout_bankwise",
  projectionProjectwise: "reports/get_projection_projectwise",
  projectionCrmwise: "reports/get_projection_crmwise",
  nocProjectwiseReport: "reports/get_noc_project_wise",
  nocCrmwiseReport: "reports/get_noc_crm_wise",
  RegistrationstatusProjectwiseReport: "reports/get_registration_project_wise",
  RegistrationstatusCrmwiseReport: "reports/get_registration_crm_wise",
  towerreportProjectwise: "reports/get_tower_report_projectwise",
  towerreportcategorywise:"reports/get_tower_report_categorywise",
  monthlyCollectionProjectwiseReport: "reports/get_monthly_collection_projectwise",
  monthlyCollectionCRMwiseReport: "reports/get_monthly_collection_crmtwise",
  chequeStatusReport: "reports/cheque_status_report/",
  creditChequeStatusReport: "reports/credit_cheque_status_report/",

};
export default urls;
